var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',[_c('ktv-breadcrumb',{attrs:{"folder":_vm.$t('Premium Points', 'Premium Points'),"page":_vm.$t('Report', 'Report'),"title":_vm.$t('Premium Payment Report', 'Premium Payment Report')}}),_c('b-row',[_c('b-col',{attrs:{"md":"12 mb-30"}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{ name: 'Premium Points Disburse List' }}},[_c('ktv-back',{attrs:{"label":_vm.$t('Back to disburse premium list', 'Back to disburse premium list')}})],1)],1)],1),_c('div',[_c('b-row',[_c('b-col',[_c('h2',[_vm._v(_vm._s(_vm.$t("Premium Payment Report", "Premium Payment Report"))+" ("+_vm._s(_vm.$route.params.id)+")")])])],1),_c('div',{staticClass:"mt-32"},[_c('ktv-table',{attrs:{"columns":_vm.columns,"filter":false,"is-loading":_vm.is.loading,"line-numbers":false,"rows":_vm.premiumPaymentReportData,"search-enabled":false,"search-custom-enabled":false,"total-rows":_vm.premiumPaymentReportTotalRows,"min-height":"250px","mode":"remote"},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"actionleft",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('ktv-button',{attrs:{"disabled":!_vm.premiumPaymentReportStatusType || _vm.premiumPaymentReportStatusType !== 235,"text":_vm.$t('Export to PDF', 'Export to PDF'),"tooltip":!_vm.premiumPaymentReportStatusType || _vm.premiumPaymentReportStatusType !== 235
												? _vm.$t(
													'Unable to export, disburse status has not been closed',
													'Unable to export, disburse status has not been closed'
												)
												: '',"color":"light","icon":"download"},on:{"click":function($event){return _vm.exportReport('pdf')}}}),_c('ktv-button',{staticClass:"ml-2",attrs:{"disabled":!_vm.premiumPaymentReportStatusType || _vm.premiumPaymentReportStatusType !== 235,"text":_vm.$t('Export to Excel', 'Export to Excel'),"tooltip":!_vm.premiumPaymentReportStatusType || _vm.premiumPaymentReportStatusType !== 235
												? _vm.$t(
													'Unable to export, disburse status has not been closed',
													'Unable to export, disburse status has not been closed'
												)
												: '',"color":"light","icon":"download"},on:{"click":function($event){return _vm.exportReport('xlsx')}}})],1)]},proxy:true},{key:"columns",fn:function(ref){
												var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.$t(props.column.label, props.column.label))+" ")]}},{key:"rows",fn:function(ref){
												var props = ref.props;
return [(props.column.field === 'number')?_c('span',[_vm._v(_vm._s(props.row.originalIndex + (_vm.params.page - 1) * _vm.params.limit + 1))]):(props.column.field === 'trxId')?_c('span',[_vm._v(" "+_vm._s(props.row.trxId ? props.row.trxId : "-")+" ")]):(props.column.field === 'farmerUid')?_c('span',[_vm._v(" "+_vm._s(props.row.farmerUid ? props.row.farmerUid : "-")+" ")]):(props.column.field === 'farmerName')?_c('span',[_vm._v(_vm._s(props.row.farmerName ? props.row.farmerName : "-"))]):(props.column.field === 'farmerType')?_c('span',[_vm._v(" "+_vm._s(props.row.farmerType ? props.row.farmerType : "-")+" ")]):(props.column.field === 'premiumReceived')?_c('span',[_vm._v(" "+_vm._s(props.row.premiumReceived >= 0 ? _vm.IDRFilter(Math.round(props.row.premiumReceived)) : "-")+" ")]):(props.column.field === 'exchangePremium')?_c('span',[_vm._v(" "+_vm._s(props.row.exchangePremium >= 0 ? _vm.IDRFilter(Math.round(props.row.exchangePremium)) : "-")+" ")]):(props.column.field === 'remainingPremium')?_c('span',[_vm._v(" "+_vm._s(props.row.remainingPremium >= 0 ? _vm.IDRFilter(Math.round(props.row.remainingPremium)) : "-")+" ")]):(props.column.field === 'disburseDate')?_c('span',[_vm._v(" "+_vm._s(props.row.disburseDate ? props.row.disburseDate : "-")+" ")]):(props.column.field === 'farmerSubmittedDate')?_c('span',[_vm._v(" "+_vm._s(props.row.farmerSubmittedDate ? props.row.farmerSubmittedDate : "-")+" ")]):(props.column.field === 'premiumReceivedDate')?_c('span',[_vm._v(" "+_vm._s(props.row.premiumReceivedDate ? props.row.premiumReceivedDate : "-")+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }