<template>
	<div class="main-content">
		<div>
			<ktv-breadcrumb
				:folder="$t('Premium Points', 'Premium Points')"
				:page="$t('Report', 'Report')"
				:title="$t('Premium Payment Report', 'Premium Payment Report')"
			/>
			<b-row>
				<b-col md="12 mb-30">
					<b-row class="mb-4">
						<b-col>
							<router-link v-b-tooltip.hover :to="{ name: 'Premium Points Disburse List' }">
								<ktv-back :label="$t('Back to disburse premium list', 'Back to disburse premium list')" />
							</router-link>
						</b-col>
					</b-row>
					<div>
						<b-row>
							<b-col>
								<h2>{{ $t("Premium Payment Report", "Premium Payment Report") }} ({{ $route.params.id }})</h2>
							</b-col>
						</b-row>
						<div class="mt-32">
							<ktv-table
								:columns="columns"
								:filter="false"
								:is-loading="is.loading"
								:line-numbers="false"
								:rows="premiumPaymentReportData"
								:search-enabled="false"
								:search-custom-enabled="false"
								:total-rows="premiumPaymentReportTotalRows"
								min-height="250px"
								mode="remote"
								@on-page-change="onPageChange"
								@on-per-page-change="onPerPageChange"
								@on-sort-change="onSortChange"
							>
								<template #actionleft>
									<div class="d-flex">
										<ktv-button
											:disabled="!premiumPaymentReportStatusType || premiumPaymentReportStatusType !== 235"
											:text="$t('Export to PDF', 'Export to PDF')"
											:tooltip="
												!premiumPaymentReportStatusType || premiumPaymentReportStatusType !== 235
													? $t(
														'Unable to export, disburse status has not been closed',
														'Unable to export, disburse status has not been closed',
													)
													: ''
											"
											color="light"
											icon="download"
											@click="exportReport('pdf')"
										/>
										<ktv-button
											:disabled="!premiumPaymentReportStatusType || premiumPaymentReportStatusType !== 235"
											:text="$t('Export to Excel', 'Export to Excel')"
											:tooltip="
												!premiumPaymentReportStatusType || premiumPaymentReportStatusType !== 235
													? $t(
														'Unable to export, disburse status has not been closed',
														'Unable to export, disburse status has not been closed',
													)
													: ''
											"
											class="ml-2"
											color="light"
											icon="download"
											@click="exportReport('xlsx')"
										/>
									</div>
								</template>
								<template #columns="{ props }">
									{{ $t(props.column.label, props.column.label) }}
								</template>
								<template #rows="{ props }">
									<span v-if="props.column.field === 'number'">{{ props.row.originalIndex + (params.page - 1) * params.limit + 1 }}</span>
									<span v-else-if="props.column.field === 'trxId'">
										{{ props.row.trxId ? props.row.trxId : "-" }}
									</span>
									<span v-else-if="props.column.field === 'farmerUid'">
										{{ props.row.farmerUid ? props.row.farmerUid : "-" }}
									</span>
									<span v-else-if="props.column.field === 'farmerName'">{{ props.row.farmerName ? props.row.farmerName : "-" }}</span>
									<span v-else-if="props.column.field === 'farmerType'">
										{{ props.row.farmerType ? props.row.farmerType : "-" }}
									</span>
									<span v-else-if="props.column.field === 'premiumReceived'">
										{{ props.row.premiumReceived >= 0 ? IDRFilter(Math.round(props.row.premiumReceived)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'exchangePremium'">
										{{ props.row.exchangePremium >= 0 ? IDRFilter(Math.round(props.row.exchangePremium)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'remainingPremium'">
										{{ props.row.remainingPremium >= 0 ? IDRFilter(Math.round(props.row.remainingPremium)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'disburseDate'">
										{{ props.row.disburseDate ? props.row.disburseDate : "-" }}
									</span>
									<span v-else-if="props.column.field === 'farmerSubmittedDate'">
										{{ props.row.farmerSubmittedDate ? props.row.farmerSubmittedDate : "-" }}
									</span>
									<span v-else-if="props.column.field === 'premiumReceivedDate'">
										{{ props.row.premiumReceivedDate ? props.row.premiumReceivedDate : "-" }}
									</span>
								</template>
							</ktv-table>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import { KtvBack, KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters } from "vuex"

	export default {
		name: "PremiumPointsPremiumPaymentReport",
		metaInfo: {
			title: "View Premium Points Premium Payment Report",
		},
		components: { KtvBack, KtvButton, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "number",
						html: true,
						label: "Number",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "trxId",
						label: "ID Trx",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerUid",
						label: "Farmer UID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerName",
						label: "Farmer Name",
						thClass: "text-left",
						width: "200px",
					},
					{
						field: "farmerType",
						label: "Farmer Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "premiumReceived",
						label: "Premium Points Received",
						tdClass: "text-right",
						thClass: "text-center",
						width: "175px",
					},
					{
						field: "exchangePremium",
						label: "Exchange Premium Points",
						tdClass: "text-right",
						thClass: "text-center",
						width: "175px",
					},
					{
						field: "remainingPremium",
						label: "Remaining Premium Points",
						tdClass: "text-right",
						thClass: "text-center",
						width: "175px",
					},
					{
						field: "disburseDate",
						label: "Disbursement Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "farmerSubmittedDate",
						label: "Farmer Submitted Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "premiumReceivedDate",
						label: "Premium Received Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
				],
				is: {
					loading: false,
				},
				params: {
					disburseId: parseInt(String(this.$route.params.id).replaceAll("PRE", ""), 10) || null,
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			}
		},
		computed: {
			...mapGetters({
				premiumPaymentReportData: "PREMIUMPOINT/premiumPaymentReportData",
				premiumPaymentReportStatusType: "PREMIUMPOINT/premiumPaymentReportStatusType",
				premiumPaymentReportTotalRows: "PREMIUMPOINT/premiumPaymentReportTotalRows",
			}),
		},
		watch: {
			"$route.params.id": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							this.getData()
						}
					} else {
						this.$swal(
							`${this.$t("Failed", "Failed")}!`,
							this.$t("Please make sure to input valid disburse id", "Please make sure to input valid disburse id"),
							"error",
						)

						this.$router.push({
							name: "Premium Points Disburse List",
						})
					}
				},
				immediate: true,
			},
		},
		beforeDestroy() {
			this.resetPremiumPaymentReport()
		},
		methods: {
			...mapActions({
				getPremiumPaymentReport: "PREMIUMPOINT/getPremiumPaymentReport",
				getReportExport: "PREMIUMPOINT/getReportExport",
				resetPremiumPaymentReport: "PREMIUMPOINT/resetPremiumPaymentReport",
			}),
			downloadFile(url, fileName) {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const link = document.createElement("a")
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						this.$swal(err.name, errorMessage, "error")
					})
			},
			exportReport(extension = "pdf") {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getReportExport({
					disburseId: this.params.disburseId,
					extension: extension,
					type: "premiumPaymentReport",
				})
					.then((transactionReport) => {
						transactionReport.forEach(({ fileName, url }) => {
							const fileExtension = fileName.split(".").pop()

							const replacedFileName = `${this.$t("Premium Payment Report", "Premium Payment Report")} (${
								this.$route.params.id
							}).${fileExtension}`
							this.downloadFile(url, replacedFileName)
						})

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPremiumPaymentReport(this.params)
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
